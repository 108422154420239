import { useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { Spinner } from "@/components/ui/spinner"
import { useSearchParams } from "next/navigation"
import * as Sentry from "@sentry/nextjs"
import { useIsMedium } from "@/lib/frontend/util"
import { cn } from "@/lib/frontend/shadcn"

let showMaintenance = false

const testReset = () => {
  window.location.href = "/"
}

/**
 * Error page component for when the application crashes extremely hard. It will
 * show az loader for 500ms and attempt to reload the page. If it fails, it will
 * then show a maintenance message with a button to reset.
 *
 * To trigger this flow manually, you can add the query parameter `ff_error_page`
 * which will cause the flow to show the loader, then the message and clicking
 * try again will clear the query parameter.
 */
export function GlobalErrorPage({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const isMedium = useIsMedium()
  const searchParams = useSearchParams()

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (!showMaintenance) {
        reset()
        showMaintenance = true
      }
    }, 500)

    return () => clearTimeout(timeoutID)
  }, [reset])

  if (!showMaintenance) {
    return (
      <Flex justify="center" align="center" direction="column" className={cn("h-screen m-auto")}>
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <Flex
      justify="center"
      direction="column"
      gap="sm"
      // p={isMedium ? "lg" : undefined}
      className={cn("h-screen m-auto max-w-[400px] max-sm:p-4")}
    >
      <Text
        weight="semibold"
        className={cn("text-lg")}
        size={isMedium ? "md" : undefined}
      >{`Uh oh, that doesn't look right...  👀`}</Text>
      <Text>
        Workmaps is currently going through maintenance. If issue persits after refreshing, please try again in a few
        minutes. Sorry for the inconvenience!
      </Text>
      <Button
        color="dark"
        fullWidth
        className={cn("mt-2")}
        variant="dark"
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => (searchParams?.has("ff_error_page") ? testReset() : reset())
        }
      >
        Try again
      </Button>
    </Flex>
  )
}
